import { get as _get } from 'lodash-es'
import footerNavigation from 'components/Footer/footerNavigation.json'
import { Footer } from '@achieve/cx-universal-footer'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import Typography from 'components/Contentful/Typography'

export default function FooterContainer({
  pageDisclosure,
  disabledRoutes,
  content,
  secondaryDisclosures,
  showNav = true,
}) {
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()

  let footerMenu = footerNavigation.footerMenus
  const brandCopy = _get(content, 'logoHelpText')
  const menuContent = _get(content, 'footerItems')
  const copyrightMessage = _get(
    content,
    'copyrightMessage.content[0].content[0].value',
    'Achieve.com. All rights reserved.'
  )

  if (menuContent) {
    footerMenu = []
    for (let i = 0; i < menuContent.length; i++) {
      const element = menuContent[i]
      const subMneu = []
      for (let j = 0; j < element?.fields?.gridItems.length; j++) {
        const subElement = element?.fields?.gridItems[j]
        const label = _get(subElement, 'fields.linkText.content[0].content[0].value')
        const url = _get(subElement, 'fields.linkHref')
        if (!disabledRoutes.includes(url) && url.length && label.length) {
          subMneu.push({
            label,
            url,
          })
        }
      }
      if (subMneu.length) {
        footerMenu.push({
          menuLabel: _get(element, 'fields.name'),
          menuItems: subMneu,
        })
      }
    }
  }
  return (
    <Footer
      footerNavigation={footerMenu}
      pageDisclosure={[pageDisclosure, ...secondaryDisclosures]?.map((dataDisclosure, index) => (
        <Typography
          key={index}
          content={dataDisclosure}
          variant="ascendBodyXs"
          fontWeight="regular"
        />
      ))}
      logoImage="/2022_Achieve_Logo_RGB.svg"
      brandCopy={brandCopy}
      trackingCB={analyticsTrackEvent}
      copyrightMessage={copyrightMessage}
      showNav={showNav}
    />
  )
}
